<div class="flex-row items-stretch lg:flex">
  <app-map
    class="flex h-[660px] w-1/3 flex-auto items-center justify-center bg-white p-[32px]"
    [highlights]="highlights()"
    [currentHighlight]="currentHighlight()"
    [currentHighlightIdx]="currentHighlightIdx()"
    (highlightClicked)="this.currentHighlightIdx.set($event)"
  ></app-map>
  <div
    class="relative w-2/3 flex-[2_2_auto] overflow-hidden bg-cover bg-no-repeat"
  >
    <!-- The highlight can either contain an image or a video. If both are set, the image takes precedence. -->
    @if (this.currentHighlight().imgSrc) {
      <img
        class="absolute left-0 top-0 h-full w-full object-cover saturate-[30%]"
        [src]="this.currentHighlight().imgSrc"
      />
    } @else if (this.currentHighlight().videoSrc) {
      <video
        class="absolute left-0 top-0 h-full w-full object-cover saturate-[30%]"
        muted
        autoplay
        loop
        [src]="this.currentHighlight().videoSrc"
      ></video>
    }

    <div class="absolute left-0 top-0 h-full w-full bg-whiteVertical"></div>

    <app-highlight-info
      [currentHighlight]="currentHighlight()"
    ></app-highlight-info>
  </div>
</div>
