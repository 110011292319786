<div class="border-t-[1px] border-solid border-gray-300 pt-[8px]">
  <div class="text-[24px] font-bold text-gray-300">{{ title() }}</div>
  <div class="lg:flex flex-row gap-[48px] lg:mt-[32px] items-center">
    <div
      class="py-6 text-[20px] font-normal leading-[24px] text-gray-400 lg:w-[300px]"
    >
      {{ infoText() }}
    </div>
    <div class="flex flex-col gap-[24px]">
      <ng-content></ng-content>
    </div>
  </div>
</div>
