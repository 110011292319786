<svg
  viewBox="0 0 437 377"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xml:space="preserve"
  xmlns:serif="http://www.serif.com/"
  class="max-w-[200px] mx-auto"
  style="
    width: 100%;
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
  "
>
  <g transform="matrix(1.00773,0,0,0.999433,0.927978,0.608575)">
    <g id="Layer1">
      <rect
        x="-0.921"
        y="-0.609"
        width="433.55"
        height="376.513"
        style="fill: white"
      />
    </g>
  </g>
  <g transform="matrix(1,0,0,1,-364.147,-467.647)">
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M80.339,141.566L79.655,141.566L79.961,139.813L78.158,139.813L77.847,141.566L77.162,141.566L77.858,137.626L78.542,137.626L78.258,139.223L80.067,139.223L80.35,137.626L81.035,137.626L80.339,141.566Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M83.861,141.566L81.218,141.566L81.914,137.626L84.395,137.626L84.29,138.227L82.476,138.227L82.298,139.245L84.028,139.245L83.922,139.824L82.198,139.824L81.997,140.954L83.967,140.954L83.861,141.566Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M86.838,141.566L84.468,141.566L85.163,137.626L85.847,137.626L85.258,140.954L86.943,140.954L86.838,141.566Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M87.995,141.566L87.311,141.566L88.006,137.626L88.691,137.626L87.995,141.566Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M93.303,138.038L92.802,138.439C92.654,138.209 92.426,138.094 92.118,138.094C91.944,138.094 91.778,138.146 91.623,138.25C91.471,138.35 91.395,138.5 91.395,138.7C91.395,138.83 91.439,138.936 91.528,139.017C91.621,139.099 91.758,139.173 91.94,139.24C92.303,139.373 92.572,139.531 92.747,139.713C92.925,139.891 93.014,140.123 93.014,140.408C93.014,140.694 92.936,140.935 92.78,141.132C92.624,141.328 92.435,141.467 92.212,141.549C91.994,141.627 91.753,141.666 91.489,141.666C91.215,141.666 90.953,141.612 90.705,141.504C90.456,141.393 90.265,141.234 90.132,141.026L90.688,140.642C90.762,140.776 90.875,140.883 91.027,140.965C91.179,141.046 91.344,141.087 91.523,141.087C91.734,141.087 91.914,141.035 92.062,140.931C92.214,140.827 92.29,140.668 92.29,140.453C92.29,140.29 92.229,140.158 92.107,140.058C91.984,139.958 91.808,139.867 91.578,139.785C91.3,139.685 91.083,139.559 90.927,139.407C90.775,139.251 90.699,139.032 90.699,138.75C90.699,138.372 90.844,138.073 91.133,137.854C91.426,137.636 91.778,137.526 92.19,137.526C92.402,137.526 92.611,137.571 92.819,137.66C93.027,137.749 93.188,137.875 93.303,138.038Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M96.157,141.566L93.514,141.566L94.21,137.626L96.691,137.626L96.586,138.227L94.772,138.227L94.594,139.245L96.324,139.245L96.218,139.824L94.494,139.824L94.293,140.954L96.263,140.954L96.157,141.566Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M98.95,139.807L99.768,141.566L98.995,141.566L98.249,139.885L97.726,139.885L97.437,141.566L96.764,141.566L97.459,137.626L98.628,137.626C99.058,137.626 99.392,137.717 99.629,137.899C99.866,138.077 99.985,138.326 99.985,138.645C99.985,138.952 99.892,139.207 99.707,139.407C99.525,139.603 99.273,139.737 98.95,139.807ZM98.338,139.323C98.965,139.323 99.279,139.121 99.279,138.717C99.279,138.531 99.214,138.4 99.084,138.322C98.954,138.244 98.767,138.205 98.522,138.205L98.027,138.205L97.826,139.323L98.338,139.323Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M104.075,137.626L101.682,141.566L101.048,141.566L100.408,137.626L101.131,137.626L101.526,140.675L101.549,140.675L103.301,137.626L104.075,137.626Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M104.42,141.566L103.735,141.566L104.431,137.626L105.115,137.626L104.42,141.566Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M108.364,140.559L108.804,140.948C108.426,141.426 107.904,141.666 107.24,141.666C106.714,141.666 106.289,141.51 105.966,141.198C105.644,140.887 105.482,140.479 105.482,139.974C105.482,139.292 105.705,138.713 106.15,138.238C106.599,137.764 107.15,137.526 107.802,137.526C108.088,137.526 108.351,137.582 108.592,137.693C108.837,137.804 109.021,137.949 109.143,138.127L108.604,138.533C108.533,138.422 108.426,138.326 108.281,138.244C108.14,138.159 107.973,138.116 107.78,138.116C107.328,138.116 106.949,138.296 106.645,138.656C106.345,139.012 106.194,139.422 106.194,139.885C106.194,140.223 106.295,140.505 106.495,140.731C106.695,140.954 106.972,141.065 107.324,141.065C107.747,141.065 108.094,140.896 108.364,140.559Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-113.86,-435.601)">
      <path
        d="M111.786,141.566L109.143,141.566L109.839,137.626L112.32,137.626L112.215,138.227L110.401,138.227L110.223,139.245L111.953,139.245L111.847,139.824L110.123,139.824L109.922,140.954L111.892,140.954L111.786,141.566Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-218.392,-481.378)">
      <path
        d="M88.543,141.566L84.703,141.566L84.447,139.838L80.767,139.838L79.823,141.566L75.695,141.566L82.655,130.238L86.559,130.238L88.543,141.566ZM84.143,137.006L83.807,134.094L82.191,137.006L84.143,137.006Z"
        style="fill: rgb(5, 64, 93)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-218.392,-481.378)">
      <path
        d="M99.967,141.566L95.807,141.566L94.255,137.422L93.807,137.422L93.103,141.566L89.535,141.566L91.535,130.238L96.191,130.238C97.439,130.238 98.426,130.515 99.151,131.07C99.876,131.614 100.239,132.382 100.239,133.374C100.239,134.291 100.02,135.059 99.583,135.678C99.146,136.296 98.538,136.718 97.759,136.942L99.967,141.566ZM94.991,135.07C96.036,135.07 96.559,134.723 96.559,134.03C96.559,133.454 96.132,133.166 95.279,133.166L94.591,133.166L94.255,135.07L94.991,135.07Z"
        style="fill: rgb(5, 64, 93)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-218.392,-481.378)">
      <path
        d="M112.303,131.502L109.807,134.126C109.402,133.507 108.767,133.198 107.903,133.198C107.124,133.198 106.452,133.502 105.887,134.11C105.322,134.718 105.039,135.512 105.039,136.494C105.039,137.155 105.226,137.683 105.599,138.078C105.983,138.472 106.554,138.67 107.311,138.67C107.695,138.67 108.031,138.611 108.319,138.494L108.495,137.502L106.639,137.502L107.151,134.59L112.287,134.59L111.199,140.766C109.802,141.512 108.351,141.886 106.847,141.886C105.119,141.886 103.743,141.448 102.719,140.574C101.695,139.688 101.183,138.456 101.183,136.878C101.183,134.915 101.812,133.267 103.071,131.934C104.34,130.59 105.956,129.918 107.919,129.918C108.89,129.918 109.759,130.062 110.527,130.35C111.295,130.627 111.887,131.011 112.303,131.502Z"
        style="fill: rgb(5, 64, 93)"
      />
    </g>
    <g transform="matrix(7.69586,0,0,8.23769,-218.392,-481.378)">
      <path
        d="M119.007,141.854C117.226,141.854 115.844,141.384 114.863,140.446C113.882,139.496 113.391,138.248 113.391,136.702C113.391,134.782 114.026,133.176 115.295,131.886C116.575,130.595 118.234,129.95 120.271,129.95C122.063,129.95 123.45,130.419 124.431,131.358C125.412,132.296 125.903,133.544 125.903,135.102C125.903,137.022 125.263,138.627 123.983,139.918C122.714,141.208 121.055,141.854 119.007,141.854ZM122.047,135.374C122.047,134.776 121.86,134.286 121.487,133.902C121.124,133.507 120.618,133.31 119.967,133.31C119.167,133.31 118.511,133.619 117.999,134.238C117.498,134.856 117.247,135.576 117.247,136.398C117.247,136.995 117.428,137.491 117.791,137.886C118.164,138.27 118.682,138.462 119.343,138.462C120.143,138.462 120.794,138.158 121.295,137.55C121.796,136.931 122.047,136.206 122.047,135.374Z"
        style="fill: rgb(5, 64, 93)"
      />
    </g>
  </g>
  <g transform="matrix(1,0,0,1,-350.11,-472.441)">
    <g transform="matrix(1.5,0,0,1.5,-2012.1,-922.901)">
      <path
        d="M1577.62,1153.47L1607.88,1117.41C1620.67,1128.14 1636.84,1134.03 1653.54,1134.03C1657.68,1134.03 1661.81,1133.67 1665.88,1132.95L1674.05,1179.31C1667.28,1180.5 1660.42,1181.1 1653.54,1181.1C1625.78,1181.1 1598.89,1171.32 1577.62,1153.47Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(1.5,0,0,1.5,-2011.99,-947.573)">
      <path
        d="M1755.83,1003.94L1715.06,1027.47C1704.56,1009.29 1686.56,996.68 1665.88,993.034L1674.05,946.676C1708.43,952.739 1738.37,973.702 1755.83,1003.94Z"
        style="fill: rgb(133, 204, 239)"
      />
    </g>
    <g transform="matrix(1.5,0,0,1.5,-1929.43,-923.951)">
      <path
        d="M1577.62,1153.47L1607.88,1117.41C1620.67,1128.14 1636.84,1134.03 1653.54,1134.03C1657.68,1134.03 1661.81,1133.67 1665.88,1132.95L1674.05,1179.31C1667.28,1180.5 1660.42,1181.1 1653.54,1181.1C1625.78,1181.1 1598.89,1171.32 1577.62,1153.47Z"
        style="fill: rgb(28, 151, 212)"
      />
    </g>
    <g transform="matrix(1.5,0,0,1.5,-1929.31,-947.573)">
      <path
        d="M1755.83,1003.94L1715.06,1027.47C1704.56,1009.29 1686.56,996.68 1665.88,993.034L1674.05,946.676C1708.43,952.739 1738.37,973.702 1755.83,1003.94Z"
        style="fill: rgb(28, 151, 212)"
      />
    </g>
    <g transform="matrix(1.5,0,0,1.5,-1846.75,-947.573)">
      <path
        d="M1755.83,1003.94L1715.06,1027.47C1704.56,1009.29 1686.56,996.68 1665.88,993.034L1674.05,946.676C1708.43,952.739 1738.37,973.702 1755.83,1003.94Z"
        style="fill: rgb(23, 128, 181)"
      />
    </g>
    <g transform="matrix(1.5,0,0,1.5,-1846.75,-923.951)">
      <path
        d="M1577.62,1153.47L1607.88,1117.41C1620.67,1128.14 1636.84,1134.03 1653.54,1134.03C1657.68,1134.03 1661.81,1133.67 1665.88,1132.95L1674.05,1179.31C1667.28,1180.5 1660.42,1181.1 1653.54,1181.1C1625.78,1181.1 1598.89,1171.32 1577.62,1153.47Z"
        style="fill: rgb(23, 128, 181)"
      />
    </g>
  </g>
</svg>
