<div class="bg-gray-50">
  <div class="mx-auto max-w-[1000px] bg-gray-50 px-6 py-[64px]">
    <h3 class="mb-[64px] text-[32px] font-bold text-gray-500">
      Rundflug buchen
    </h3>
    <app-contact-data
      class="mb-[64px] block"
      [group]="this.contactDataFormGroup"
    ></app-contact-data>
    <app-flight-data
      class="mb-[64px] block"
      [group]="this.flightDataFormGroup"
    ></app-flight-data>
    <app-flight-selector [formControl]="flightFormControl">
      @for (flight of flights; track $index) {
        <app-flight-selector-item
          [flight]="flight"
          class="first:ml-[149px] last:mr-[149px] sm:first:ml-[249px] sm:last:mr-[249px]"
          [attr.data-flight-id]="flight?.id"
        ></app-flight-selector-item>
      }
    </app-flight-selector>
    <app-flight-summary [total]="totalFlightCost | async"></app-flight-summary>
    <app-flight-disclaimer></app-flight-disclaimer>
    @let currentErrorEmailLink = errorEmailLink();
    @if (currentErrorEmailLink) {
      <app-form-error
        [contactEmail]="config.contactEmail"
        [contactPhone]="config.contactPhone"
        [errorEmailLink]="currentErrorEmailLink"
      ></app-form-error>
    }
    <app-button
      appButtonLarge
      class="mt-[32px] block"
      label="Buchen"
      [enabled]="bookingFormGroup.valid && !this.loading()"
      (click)="submit()"
    ></app-button>
  </div>
</div>
