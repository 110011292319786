<div class="bg-primary-500 pt-4 sm:pb-16">
  <h3 class="p-8 text-3xl font-bold text-primary-900 sm:p-16 sm:text-4xl">
    Erfahrungsberichte
  </h3>
  <div
    class="flex snap-x snap-mandatory flex-row items-stretch gap-x-8 overflow-x-scroll p-8 sm:gap-x-16 sm:px-16"
  >
    <ng-content></ng-content>
  </div>
</div>
