<div
  class="relative flex h-[800px] flex-row bg-[url('/start/portrait.webp')] bg-[left_-150px_bottom_-10px] bg-no-repeat p-16 xl:items-center xl:justify-end xl:bg-[left_-0px_bottom_-10px]"
>
  <div
    class="relative z-10 flex flex-col gap-y-6 sm:max-w-[500px] xl:basis-1/3"
  >
    <h1 class="text-5xl font-bold text-secondary-500">Grüezi!</h1>
    <p
      class="text-md font-medium leading-7 text-gray-500 sm:text-lg xl:text-xl xl:leading-9"
    >
      Ich bin Mathis (30) aus Winterthur. Seit ich denken kann, bin ich
      begeistert von der Fliegerei. Nun möchte ich meine Passion für das Fliegen
      mit möglichst vielen Leuten teilen. Es würde mich freuen, Sie bald mit an
      Bord begrüssen zu dürfen.
    </p>
  </div>
  <div
    class="absolute bottom-0 left-0 block h-full w-full bg-gradient-to-b from-white via-white via-40% to-transparent to-50% xl:hidden"
  ></div>
</div>
