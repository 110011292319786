@let flightSnapshot = flight();
<app-hero [id]="flightSnapshot.title">
  <img
    appHeroLayer
    class="absolute w-full min-w-[1420px]"
    [startValue]="flightSnapshot.hero.background.startValue"
    [endValue]="flightSnapshot.hero.background.endValue"
    [property]="flightSnapshot.hero.background.property"
    [ngSrc]="flightSnapshot.hero.background.imgSrc"
    width="1"
    [height]="flightSnapshot.hero.background.aspectRatio"
    priority
  />
  <div
    appHeroLayer
    class="absolute w-full text-center font-bold text-secondary-300 mix-blend-plus-lighter"
    style="font-size: calc(min(17vw, 128px))"
    [startValue]="flightSnapshot.hero.text.startValue"
    [endValue]="flightSnapshot.hero.text.endValue"
    [property]="flightSnapshot.hero.text.property"
  >
    {{ flightSnapshot.title }}
  </div>
  <img
    appHeroLayer
    class="absolute w-full min-w-[1420px]"
    [startValue]="flightSnapshot.hero.foreground.startValue"
    [endValue]="flightSnapshot.hero.foreground.endValue"
    [property]="flightSnapshot.hero.foreground.property"
    [ngSrc]="flightSnapshot.hero.foreground.imgSrc"
    width="1"
    [height]="flightSnapshot.hero.foreground.aspectRatio"
  />
  <div
    appHeroLayer
    [startValue]="flightSnapshot.hero.duration.startValue"
    [endValue]="flightSnapshot.hero.duration.endValue"
    [property]="flightSnapshot.hero.duration.property"
    class="absolute flex w-full flex-row items-center justify-center gap-[2px] mix-blend-plus-lighter"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      class="size-6 stroke-secondary-300 stroke-2 pr-[8px]"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <span class="text-2xl font-bold text-secondary-300">
      {{ flightSnapshot.duration }}
    </span>
    <span class="text-2xl text-secondary-300">min</span>
  </div>
</app-hero>
<div class="flex flex-col items-center justify-center">
  <div
    class="mt-[48px] max-w-[650px] hyphens-auto text-center text-[20px] font-medium leading-[40px] text-gray-400 max-sm:p-6 sm:w-3/4"
  >
    {{ flightSnapshot.synopsis }}
  </div>

  <app-price-tag
    class="mt-[48px]"
    [price]="flightSnapshot.cost"
  ></app-price-tag>

  <app-button
    class="mb-[64px] mt-[16px]"
    label="Buchen"
    link="/booking"
    [queryParams]="{ flight: flightSnapshot.id }"
  ></app-button>
</div>
@if (flightSnapshot.highlights) {
  <app-highlights
    class="hidden lg:block"
    [highlights]="flightSnapshot.highlights"
  ></app-highlights>
  <app-highlights-small
    class="block lg:hidden"
    [highlights]="flightSnapshot.highlights"
  ></app-highlights-small>
}
