<div class="flex flex-col gap-[8px]">
  <label
    class="text-[20px] font-bold text-gray-700"
    [htmlFor]="formControlId()"
    >{{ label() }}</label
  >
  <input
    class="shadow-insetSm h-[48px] w-[300px] rounded-[5px] p-[10px] text-[20px] font-normal text-gray-700 focus:border-none focus:outline focus:outline-2 focus:outline-primary-500"
    [type]="type()"
    [id]="formControlId()"
    [formControl]="control()"
    [min]="min()"
    [max]="max()"
  />
</div>
