<div>
  <app-hero id="start">
    <img
      appHeroLayer
      property="top"
      [startValue]="-90"
      [endValue]="-50"
      alt="Foto aus dem Helikopter-Cockpit"
      src="/start/hero.jpg"
      class="absolute w-full min-w-[1420px]"
    />
    <div
      appHeroLayer
      class="absolute left-[5%] font-bold text-secondary-300 mix-blend-luminosity"
      style="
        font-size: calc(min(10vw, 96px));
        line-height: calc(min(10vw, 96px));
      "
      [startValue]="25"
      [endValue]="45"
      property="top"
    >
      Einsteigen<br />und Abheben!
    </div>
  </app-hero>

  <app-offerings></app-offerings>

  <app-reviews>
    <app-review
      class="snap-center snap-always"
      title="Super Säntisflug zu viert"
      imgSrc="/start/reviews/01.jpg"
      name="Mihai & Hugo"
    >
      Wir hatten einen super Spätsommerflug mit Mathis ab Sitterdorf.
    </app-review>
    <app-review
      class="snap-center snap-always"
      title="Brutal gsi 😍"
      imgSrc="/start/reviews/02.jpg"
      name="Nicolas"
    >
      Dude isch nöd eimal abgstürzt!
    </app-review>
    <app-review
      class="snap-center snap-always"
      title="Wunderbare Stimmung"
      imgSrc="/start/reviews/03.jpg"
      name="Heidi & Kurt"
    >
      Wir hatten einen herrlichen Flug um den Säntis bei Sonnenuntergang. Wir
      würden jederzeit wieder mitfliegen!
    </app-review>
    <app-review
      class="snap-center snap-always"
      title="Überwältigender Alpenflug!"
      imgSrc="/start/reviews/04.jpg"
      name="Susi & Alberto"
    >
      Mit dem Helikopter bis zum Tödi! Ein Erlebnis, das wir wohl nie vergessen
      werden!
    </app-review>
  </app-reviews>

  <app-portrait></app-portrait>
</div>
