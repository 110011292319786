<div
  class="flex max-w-[600px] flex-col gap-y-6 rounded-[5px] max-sm:text-center lg:max-w-[1000px] lg:flex-row lg:items-center lg:gap-x-16"
>
  <div class="mb-4 lg:mb-0 lg:basis-1/3">
    <img class="mx-auto max-h-[200px] max-w-[400px]" [src]="imgSrc()" />
  </div>
  <div class="lg:basis-2/3">
    <h3 class="mb-4 text-xl font-bold text-primary-800 lg:text-2xl">
      {{ title() }}
    </h3>
    <p class="text-md font-medium leading-[30px] text-gray-500 lg:text-lg">
      {{ text() }}
      <ng-content></ng-content>
    </p>
  </div>
</div>
