<div class="relative">
  <app-map
    [mapSvg]="highlights().mapSvg"
    [currentHighlight]="currentHighlight()"
  ></app-map>

  <div
    #scrollContainer
    class="mt-6 flex h-screen snap-x snap-mandatory snap-always flex-row overflow-x-scroll"
    (scroll)="onScroll($event)"
  >
    @for (
      highlight of highlights().highlights;
      track $index;
      let first = $first, last = $last
    ) {
      <div
        class="relative flex h-full w-screen shrink-0 snap-center flex-col overflow-y-hidden"
        [attr.data-highlight-id]="$index"
      >
        <!-- The highlight can either contain an image or a video. If both are set, the image takes precedence. -->
        <div class="relative h-2/3 shrink-0">
          @if (highlight.imgSrc) {
            <img
              class="h-full w-full object-cover saturate-[30%]"
              [src]="highlight.imgSrc"
            />
          } @else if (highlight.videoSrc) {
            <video
              class="h-full w-full object-cover saturate-[30%]"
              muted
              autoplay
              loop
              [src]="highlight.videoSrc"
            ></video>
          }

          @if (!first) {
            <!-- chevron left -->
            <div
              class="absolute left-2 top-[50%] z-30 cursor-pointer stroke-secondary-200 mix-blend-hard-light"
              (click)="onClickPrevious()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                class="size-12"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
            </div>
          }

          @if (!last) {
            <!-- chevron right -->
            <div
              class="absolute right-2 top-[50%] z-30 cursor-pointer stroke-secondary-200 mix-blend-hard-light"
              (click)="onClickNext()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                class="size-12"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          }
        </div>
        <div
          class="absolute left-0 top-0 z-10 h-full w-full bg-whiteVertical"
        ></div>

        <div class="shrink-0 p-8">
          <h3 class="mb-4 text-2xl font-bold text-primary-700">
            {{ highlight.title }}
          </h3>
          <p
            class="hyphens-auto text-[16px] font-medium leading-[32px] text-primary-500"
          >
            {{ highlight.description }}
          </p>
        </div>
      </div>
    }
  </div>
</div>
