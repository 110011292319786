<div
  class="flex h-full w-64 flex-col items-stretch overflow-hidden rounded-[5px] bg-primary-100 shadow-sm sm:w-96"
>
  <div class="h-64 overflow-hidden sm:h-96">
    <img class="h-full w-full object-cover saturate-[75%]" [src]="imgSrc()" />
  </div>
  <div class="flex grow flex-col justify-between p-4 sm:p-8 sm:pb-6">
    <h3 class="mb-4 text-lg font-bold text-primary-900 sm:text-2xl">
      {{ title() }}
    </h3>
    <p class="italic text-primary-800">
      &laquo;<ng-content></ng-content>&raquo;
    </p>
    <div class="mt-4 sm:mt-6">
      <span class="text-primary-700">- {{ name() }}</span>
      <div
        class="flex flex-row justify-center fill-secondary-500 stroke-none pt-4"
      >
        <app-star></app-star>
        <app-star></app-star>
        <app-star></app-star>
        <app-star></app-star>
        <app-star></app-star>
      </div>
    </div>
  </div>
</div>
