<app-basic-page title="Danke für Ihre Anfrage!">
  <p class="text-[20px] leading-[30px] font-medium text-gray-600">
    Wir werden diese prüfen und uns innert zwei Arbeitstagen bei Ihnen melden.
    Bei allfälligen Fragen erreichen Sie uns per E-Mail unter
    <a
      [href]="config.contactEmail | emailLink"
      class="text-primary-700 underline"
      >{{ config.contactEmail }}</a
    >
    oder telefonisch unter
    <a
      [href]="config.contactPhone | phoneLink"
      class="text-primary-700 underline"
      >{{ config.contactPhone }}</a
    >.
  </p>
</app-basic-page>
