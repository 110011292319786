<div class="flex flex-col gap-[8px]">
  <label class="text-[20px] font-bold text-gray-700" [for]="formControlId()">{{
    label()
  }}</label>
  <select
    class="shadow-insetSm h-[48px] w-[300px] rounded-[5px] bg-white p-[10px] text-[20px] font-normal text-gray-700 focus:border-none focus:outline focus:outline-2 focus:outline-primary-500"
    [id]="formControlId()"
    [formControl]="control()"
  >
    <ng-content></ng-content>
  </select>
</div>
