import { Component } from '@angular/core';
import { BasicPageComponent } from "../../common/basic-page/basic-page.component";

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [BasicPageComponent],
  templateUrl: './privacy-policy.component.html',
})
export class PrivacyPolicyComponent {

}
