<div
  class="my-4 flex flex-col items-stretch justify-center gap-x-[100px] sm:my-0 sm:flex-row sm:items-center sm:px-16 sm:py-8"
>
  @for (route of routes(); track route.path) {
    <a
      class="py-4 text-[16px] font-semibold text-gray-300 max-sm:px-8 sm:py-2 sm:text-[20px]"
      [routerLink]="route.path"
      routerLinkActive="!text-gray-600 max-sm:bg-gray-50"
      >{{ route.title }}</a
    >
  }
</div>
