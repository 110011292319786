<app-basic-page title="Impressum">
  <address class="not-italic">
    Mathis Dedial<br />
    Albisstrasse 124<br />
    8038 Zürich <br />
    <a
      class="underline text-primary-600"
      [href]="config.contactEmail | emailLink"
    >
      {{ config.contactEmail }} </a
    ><br />
    <a
      class="underline text-primary-600"
      [href]="config.contactPhone | phoneLink"
      >{{ config.contactPhone }}</a
    >
  </address>
</app-basic-page>
