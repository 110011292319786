<div
  class="mb-8 flex flex-col items-stretch gap-y-24 px-8 py-16 sm:items-center sm:gap-y-32 sm:py-32"
>
  <app-offering
    title="Rundflüge ab zwei Standorten in der Ostschweiz"
    imgSrc="/start/bases.svg"
  >
    Ihren Rundflug können Sie ab den Flugplätzen Sitterdorf im Thurgau oder St.
    Gallen - Altenrhein nähe Rorschach buchen. Auf Anfrage sind auch Flüge von
    anderen Plätzen möglich.
  </app-offering>
  <app-offering title="Selbstkostenpreis" imgSrc="/start/pricing.svg">
    Sie bezahlen nur für die effektiv geflogene Flugdauer, auf die Minute genau!
    Das hält die Kosten tief. Einen
    <a class="text-primary-500 underline" routerLink="/flights/saentis"
      >Säntisrundflug</a
    >
    gibt es so schon ab CHF {{ saentisCost }}.- pro Person!
  </app-offering>
</div>
